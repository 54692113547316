const getCompanyId = () : string|undefined => {
  const currentUrl = window.location.href;

// Parse the URL
  const url = new URL(currentUrl);
  const params = url.pathname.split('/')
  const indexOfCompany = params.findIndex(param => param === 'company');
  return params[indexOfCompany+1]
}


export {
  getCompanyId
}
