import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router'
import {Locales} from "@/i18n/locales";
import { defineAsyncComponent } from 'vue';
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: defineAsyncComponent(() => import('@/views/HomeView.vue'))
  },
  {
    path: '/:lang/company/:id',
    name: 'company',
    component: defineAsyncComponent(() => import('@/views/CompanyView.vue'))
  },
  {
    path: '/:lang/company/:id/service/:serviceId',
    name: 'service',
    component: defineAsyncComponent(() => import('@/views/ServiceView.vue'))
  },
  {
    path: '/:lang/help',
    name: 'help',
    component: defineAsyncComponent(() => import('@/views/HelpView.vue'))
  },
  {
    path: '/:lang/company/:id/checkout',
    name: 'checkout',
    component: defineAsyncComponent(() => import('@/views/CheckoutView.vue'))
  },
  {
    path: '/:lang/company/:id/checkout-done',
    name: 'checkout_done',
    component: defineAsyncComponent(() => import('@/views/CheckoutDoneView.vue'))
  },
  {
    path: '/:lang/company/:id/tips',
    name: 'tips',
    component: defineAsyncComponent(() => import('@/views/TipsView.vue'))
  },
  {
    path: '/:lang/company/:id/payment-success',
    name: 'payment.success',
    component: defineAsyncComponent(() => import('@/views/SuccessView.vue'))
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const { lang } = to.params;
  if(store.getters['modal/getIsOpen'])
    store.commit('modal/close')

  if ((!lang || !Object.keys(Locales).includes(lang.toString().toUpperCase() as Locales)) && to.path !== '/') {
    return router.push('/')
  } else {
    return next();
  }
});

export default router
