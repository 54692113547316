import { markRaw } from 'vue'
import { ModalStateType, UserStateType } from '@/types'

export const modal = {
  namespaced: true,
  state: (): ModalStateType => ({
    isOpen: false,
    view: {}
  }),
  getters: {
    getIsOpen: (state: ModalStateType) => state.isOpen,
  },
  mutations: {
    open (state: ModalStateType, view: object) {
      state.isOpen = true
      state.view = markRaw(view)
    },
    close (state: ModalStateType) {
      state.isOpen = false
      state.view = {}
    }
  }
}
