import { createEcho } from '@/services/createEcho'
import { getToken } from '@/utils/authUser'
import { getChatId } from '@/utils/initChat'
import store from '@/store'
import eventBus from '@/services/eventBus'
import { browserNotify } from '@/utils/browserNotify'


const initEcho = () => {
  const token = getToken()
  if(token.access_token) {
    window.Pusher = require('pusher-js')
    window.Echo = createEcho(token.access_token)

    window.Echo.private('chat.' + getChatId())
      .listen('.chat', (data:any) => {
        if (data.messageResource && data.messageResource.to_id === store.getters['user/getUserId']) {
          store.commit('chat/addMessage', data.messageResource)
          eventBus.emit('messageListUpdated');

          if(store.getters['chat/getIsClosed']){
            eventBus.emit('toggleAudioMute', { mute: true, isPLaying: true });
            store.commit('user/setHasNewMessages', true)
          } else {
            store.dispatch('chat/messageSeen', {chatId: getChatId()})
            store.commit('user/setHasNewMessages', false)
          }
          browserNotify()
        }
      })

    window.Echo.private('finish-chat.' + getChatId())
      .listen('.finish-chat', (data:any) => {
        store.commit('chat/finishChat')
        store.commit('chat/resetMessages')
      })
  }
}

export { initEcho }
