import axiosApi from '@/services/axiosApi'
import { setMyBagItems } from '@/utils/localStorage'
import moment from "moment";

type StateItem = {
  id: string,
  count: number,
  currency: string,
  price: number
}

type BagStateType = {
  items: Array<StateItem>,
}

export const bag = {
  namespaced: true,
  state: (): BagStateType => ({
    items: [],
  }),
  getters: {
    getItems: (state: BagStateType) => state.items,
  },
  mutations: {
    addItem (state: BagStateType, item: StateItem) {
      const index  = state.items.findIndex(stateItem => stateItem.id === item.id)
      if( index === -1) {
        state.items.push(item)
      } else {
        item.count += state.items[index].count
        state.items[index] = {...item}
      }
      setMyBagItems(state.items)
    },
    removeItem (state: BagStateType, item: StateItem) {
      const indexToRemove  = state.items.findIndex(stateItem => stateItem.id === item.id)
      if (indexToRemove > -1) {
        state.items.splice(indexToRemove, 1);
        setMyBagItems(state.items)
      }
    },
    setCount (state: BagStateType, payload: {itemId: string, count: number}) {
      const index  = state.items.findIndex(stateItem => stateItem.id === payload.itemId)
      if (index > -1) {
        state.items[index].count = payload.count
        setMyBagItems(state.items)
      }
    },
    setItems (state: BagStateType, itemsList: Array<StateItem>) {
      state.items = itemsList
    }
  },
  actions: {
    loadItems: async ({ commit }: { commit: (...args: any[]) => void }, payload: {
      serviceItems: Array<{ id: string, count: number }>,
    }) => {
      const serviceIds = payload.serviceItems.map(({ id }) => (id))

      axiosApi.post('/company/services/list', {
        'service_ids': serviceIds
      })
        .then(response => {
          let items = []
          if(response.status === 200) {
            items = response.data.data.map((item:any) => ({
              ...item,
              count: payload.serviceItems.find(({ id, count: number }) => id === item.id)?.count,
            }))
          }
          commit('setItems', items)
        })
    },
    order: async  ({ commit }: { commit: (...args: any[]) => void }, payload: {
      companyId: string,
      form: Array<{full_name:string, email:string, room_number:number, payment_method: string}>,
      serviceItems: Array<{ id: string, count: number }>,
      onSuccess: (...args: any[]) => void,
      onFailure: (...args: any[]) => void,
    }) => {
      const sentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
       axiosApi.post('/orders/'+ payload.companyId, {
         ...payload.form,
         'sent_date': sentDate,
         'items': payload.serviceItems.map(item => ({
           'service_id': item.id,
           count: item.count,
         })),
       }).then((response: any) => {
         if ( response.status === 204) {
           setMyBagItems([])
           commit('setItems', [])
           payload.onSuccess()
         }
         else if (response.response.data.errors){
           payload.onFailure(response.response.data.errors)
         }
       })
    }
  }
}
