export const fetchWithCache = async (url: string, cacheName: string, headers = {}, maxAge = 21600) => {
  const cache: Cache = await caches.open(cacheName);
  const cachedResponse: Response | undefined = await cache.match(url);

  if (cachedResponse) {
    const cachedData = await cachedResponse.json();
    const { response, timestamp } = cachedData;

    const age: number = (Date.now() - timestamp) / 1000;
    if (age < maxAge) {
      return response;
    } else {
      // Remove the expired entry
      await cache.delete(url);
    }
  }

  const networkResponse: Response = await fetch(url, { headers });
  if (networkResponse.ok) {
    const responseClone = networkResponse.clone();
    const responseData = await responseClone.json();
    const responseToCache = {
      response: responseData,
      timestamp: Date.now()
    };
    const serializedResponse: Response = new Response(JSON.stringify(responseToCache));
    await cache.put(url, serializedResponse);

    return responseData;
  } else {
    throw new Error('Network response was not ok.');
  }
};

export async function deleteCache(url: string, cacheName: string): Promise<void> {
  const cache: Cache = await caches.open(cacheName);
  await cache.delete(url);
}
