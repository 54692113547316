import store from '@/store'
import { TokenType } from '@/types'

const getToken = () => {
  const tokenJson = localStorage.getItem('mabell_auth_token')
  return tokenJson ? JSON.parse(tokenJson) : {}
}

const authenticate = async (token: TokenType, companyId:string) => {
  if (Object.prototype.hasOwnProperty.call(token, 'access_token')) {
    try {
      await store.dispatch('user/getUser', {companyId})
      store.commit('user/setToken', token.access_token)
      store.commit('chat/setFinished', store.getters['user/getUser'].chat_review_status !== "chat_active")
    } catch (e) {
      logout()
    }
  }
}

const logout = () => {
  localStorage.removeItem('mabell_chat_id')
  localStorage.removeItem('mabell_auth_token')
  store.commit('user/setUser', null)
}

export {
  getToken,
  authenticate,
  logout
}
