import en from "@/i18n/lang/en.json";
import am from "@/i18n/lang/am.json";
import ru from "@/i18n/lang/ru.json";
import { Ref, ref, UnwrapRef } from "vue";
import { MessagesType } from "@/types/locales";

export enum Locales {
  EN = 'English',
  AM = 'Armenian',
  RU = 'Russian',
}

export const messages: Record<Locales, MessagesType> = {
  [Locales.EN]: en,
  [Locales.AM]: am,
  [Locales.RU]: ru
};

export function getCurrentLang(qs: string): UnwrapRef<string> {
  const paramsArray: string[] = qs.split(/[&/?]/);
  const currentLang: Ref<string> = ref("EN");

  for (const param of paramsArray) {
    if (param && param !== "undefined" && param !== '') {
      const lang = param.toString().toUpperCase();
      if (Object.keys(Locales).includes(lang)) {
        currentLang.value = lang;
        break;
      }
    }
  }

  return currentLang.value;
}

const currentLangName: keyof typeof Locales = getCurrentLang(window.location.href) as keyof typeof Locales;
const currentLangValue: Locales = Locales[currentLangName];

export const defaultLocale: { name: "EN" | "AM" | "RU"; value: Locales; url: any } = {
  name: currentLangName,
  value: currentLangValue,
  url: require(`@/assets/icons/Flag/${currentLangValue}.svg`)
};
