type MyBagItemType = {
  id:string,
  count:number
}

const MY_BAG_KEY = 'mabell_bag'

const setMyBagItems = (items: Array<MyBagItemType>):void => {
  localStorage.setItem(MY_BAG_KEY, JSON.stringify(prepareMyBagItemsToSave(items)))
}

const getMyBagItems = ():Array<MyBagItemType> => {
  const storageData = localStorage.getItem(MY_BAG_KEY)
  return storageData ? JSON.parse(storageData) : []
}

const prepareMyBagItemsToSave = (items: Array<MyBagItemType>): Array<MyBagItemType> => {
  return items.map(({ id, count }) => ({ id, count }));
}

export {
  setMyBagItems,
  getMyBagItems
}
