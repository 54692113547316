import { ContactForm, ContactFormState } from "@/interfaces/ContactForm"
import axiosApi from "@/services/axiosApi"

export const contact = {
  state:(): ContactFormState  => ({
    contactForm: {
      business_email: '',
      full_name: '',
      company: '',
      subscription_plan: '',
      message: ''
    }
  }),
  getters: {},
  mutations: {},
  actions: {
    sendContact: async ({ commit }: { commit: (...args: any[]) => void }, payload: ContactForm) => {
      await axiosApi.post('crm/contact-us/store', payload)
    }
  }
}

