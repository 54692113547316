import axiosApi from '../services/axiosApi'
import {
  TokenType,
  UserStateType,
  RegistrationPayloadType, UserType, ChatStateType
} from '@/types'
import { AxiosResponse } from 'axios'
import { AuthResponse } from '@/types/auth'
import { logout } from '@/utils/authUser'

export const user = {
  namespaced: true,
  state: (): UserStateType => ({
    token: null,
    user: null
  }),
  getters: {
    getUserId: (state: UserStateType) => state.user ? state.user.id : null,
    getUser: (state: UserStateType) => state.user
  },
  mutations: {
    setUser (state: UserStateType, responseData: UserType | null) {
      state.user = responseData
    },
    setToken (state: UserStateType, responseData: TokenType | null) {
      state.token = responseData
    },
    setHasNewMessages (state: UserStateType, value: boolean) {
      if(state.user)
       state.user.has_unread = value
    }
  },
  actions: {
    register (context: object, payload: RegistrationPayloadType): Promise<AxiosResponse<AuthResponse>> {
      return axiosApi.post('client/' + payload.companyId + '/start-chat', { ...payload.data, ...{ phone: '' } })
    },
    getUser: async ({ commit }: { commit: (...args: any[]) => void }, payload: {companyId:string}) => {
      await axiosApi.post('client/authUser/'+ payload.companyId)
        .then(res => {
          if (res.status === 200) {
            commit('setUser', res.data)
          } else {
            logout()
            console.log(res)
          }
        })
    }
  }
}
