import store from '@/store'

const getChatId = ():string => {
  return localStorage.getItem('mabell_chat_id') || '';
}

const initChat = async () => {
  const chatId = getChatId()

  if(chatId) {
    store.commit('chat/setChatId', chatId)
    await store.dispatch('chat/getMessages', { id: chatId })
  }
}

export {
  initChat,
  getChatId
}
